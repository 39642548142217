<template lang="pug">
  tr.course-leader
    td.course-leader__id {{ item.ID }}
    td(data-test="cl-name") {{ item.name }}
    td.course-leader__status
      v-switch(
        hide-details
        :loading="loading"
        v-model="item.isArchived"
        @change="changeStatus"
        data-test="cl-status-switcher"
      )
    td.course-leader__actions
      v-btn(
        icon small
        @click="goToEdit"
        data-test="cl-edit-btn"
      )
        v-icon mdi-pencil
</template>

<script>
import errorsMixin from '@/mixins/errors.mixin'

export default {
  props: {
    item: {
      required: true,
      type: Object
    }
  },
  mixins: [errorsMixin],

  inject: ['svc'],

  data: () => {
    return {
      loading: false
    }
  },

  methods: {
    async changeStatus() {
      try {
        this.loading = true;
        await this.svc.update(this.item.getApiData());
        this.$notify({text: 'Status changed', type: 'success'});
        this.$emit('reload-list');
      } catch (error) {
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM});
        this.item.isArchived = !this.item.isArchived;
      } finally {
        this.loading = false;
      }
    },

    goToEdit() {
      this.$emit('on-edit', this.item);
    }
  }
}
</script>

<style lang="scss" scoped>
.course-leader {
  transition: background-color .3s;

  &__id {
    width: 50px;
  }

  &__status {
    vertical-align: middle;
    padding: {
      top: 4px;
      bottom: 4px;
    }

    .v-input {
      margin: 0;
      padding: 0;
    }
  }

  &__actions {
    width: 60px;
    text-align: center;
  }
}

.highlight-enter-active, .highlight-leave-active {
  transition: background 1s ease;
}

.highlight-leave-active {
  display: none;
}

.highlight-enter, .highlight-leave-to {
  background: #78dfa6;
}

.highlight-leave-to {
  display: none;
}
</style>
